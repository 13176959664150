<template>
  <nav class="navbar">
    <ul class="navbar-list">
      <li class="navbar-icon">
        <img
          class="navbar-icon__img"
          src="/images/onclusive-emblem-navigation.svg"
          alt="Onclusive Logo"
        />
      </li>
      <ClientOnly>
        <li v-for="opt in activeOptions" :key="opt.id">
          <!-- Handles authorsAndPublications case (5) -->
          <HeaderNavBarOption
            v-if="
              opt.id === 5
                ? opt.display && authorsAndPublicationsTabFlag
                : opt.display
            "
            :id="opt.id"
            :display-text="opt.translationKey"
            :path="opt.path"
            :data-intercom-target="opt.dataTarget"
            :selected-link="checkSelectionStatus(opt)"
            :active-group="activeGroup"
            @click="onClick(opt)"
          />
        </li>
      </ClientOnly>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import { computed, onMounted, Ref, ref } from "vue";
import { useAuth0 } from "@auth0/auth0-vue";
import { useNavigationStore } from "@/store/navigation";
import NavBarOption from "~/types/NavBarOptionType";
import { useLaunchDarkly } from "~/composables/useLaunchDarkly";

const auth0 = useAuth0();
const navigationStore = useNavigationStore();
const authorsAndPublicationsTabFlag: Ref<Boolean> = ref(false);

onMounted(async () => {
  if (import.meta.client && auth0.isAuthenticated.value) {
    const { getFlagValue } = await useLaunchDarkly();
    const flagValueLD = await getFlagValue("authorsAndPublicationsTab");
    authorsAndPublicationsTabFlag.value = flagValueLD ?? false;
  }
});

const activeOptions = computed(() => {
  return navigationStore.activeNavBarOptions;
});

const activeGroup = computed(() => {
  return navigationStore.activeGroup;
});

const onClick = (opt: NavBarOption) => {
  navigationStore.activeNavBarOption = opt.id;
};

const checkSelectionStatus = (opt: NavBarOption) => {
  return navigationStore.activeNavBarOption === opt.id;
};
</script>

<style scoped>
.navbar {
  @apply fixed top-0 z-20 w-full;
}
.navbar-list {
  @apply flex h-20 bg-white-200 border-solid;
}
.navbar-icon {
  @apply w-24 h-20 bg-white-200;
}
.navbar-icon__img {
  @apply object-cover w-24 h-20;
}
</style>
